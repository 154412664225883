import { gql } from "graphql-tag";

export const UploadMutation = gql`
mutation webformFileUpload($id: String!, $elements: [WebformFileUploadElement]){
  webformUploadFileUpload(id: $id, elements:$elements) {
    results{
        fid
        element
    }
    errors
    validationErrors{
        messages
        element
    }
    
  }
}
`;
