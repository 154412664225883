import { gql } from "graphql-tag";

export const SubmitFormMutation = gql`
mutation submitForm($id: String!,$elements: [WebformSubmissionElement], $sourceEntityId: String,$sourceEntityType: String){
  submitWebform(id: $id, elements: $elements, sourceEntityId: $sourceEntityId, sourceEntityType: $sourceEntityType) {
    errors
    submission{
        id
    },
    validationErrors{
        element
        messages
    }
  }
}
`;
