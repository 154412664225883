import { UploadMutation } from "../../query/form/Upload";
import type { UploadResult } from "~/interfaces/uploadResult";
import type { UploadData, UploadElement } from "~/interfaces/UploadData";

/**
 * Upload a file
 * @param client ( apollo client )
 * @param id string
 * @param data form data
 * @returns 
 */
export default async function upload(client: any, id: string, data: object) {
  const elements = await prepareUploadData(data);
  const variables = {id: id, elements:elements};
  return <UploadResult>client.mutate({
    mutation: UploadMutation,
    variables: variables,
  });
}

/**
 * Prepare UploadElement array for request
 * @param data
 * @returns Promise<UploadElement[]>
 */
const prepareUploadData = async (data: object): Promise<UploadElement[]> => {
    const elements = [];
    for (const [key, value] of Object.entries(data)) {
        if(value){
            elements.push({
                webformElementId: key,
                file: {
                    stream: await toBase64(value),
                    filename: value.name,
                    mimetype: value.type
                }
            });
        }
    }
    return elements;
}

/**
 * Return content of the file base64 encoded
 * @param file 
 * @returns string
 */
const toBase64  =  (file) => new Promise((resolve, reject) =>{
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
    reader.onerror = reject;
});