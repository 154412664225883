<template>
  <div id="success">
    <!-- Formulaire start -->
    <div>
      <h2 v-if="props.Spontaneous && showSuccess == false"
          class="pb-6 m-0 font-normal font-lora text-h3Medium lg:text-h2Small lg:pb-10 leading-10"
      >
        {{ $t("job.spontaneousTitle") }}
      </h2>
      <h2 v-if="!props.Spontaneous && showSuccess == false"
          class="pb-6 m-0 font-normal font-lora text-h3Medium lg:text-h2Small lg:pb-10"
      >
        {{ $t("job.applyJob") }}
      </h2>
      <Form v-if="showSuccess == false" :validation-schema="schema" v-slot="{ errors }" @submit="onSubmitForm">
        <div class="grid grid-cols-1 gap-4 mb-4 lg:grid-cols-2 lg:mb-5">
          <div class="flex flex-col col-span-2 lg:col-span-1">
            <label class="pb-2.5 font-bold font-raleway text-regular"
            >{{ $t("Name") }} <span class="text-red">*</span></label
            >
            <Field
                class="p-4 border rounded border-grisClair font-regular font-raleway"
                type="text"
                :label="$t('Name')"
                name="last_name"
                v-model="formData.last_name"
            />
            <span v-if="errors.last_name" class="pt-1 font-raleway text-small text-red"
            >{{ errors.last_name }}</span>
            <div v-if="validationErrors.last_name" class="pt-1 font-raleway text-small text-red"
                 v-html="validationErrors.last_name?.join('<br />')"
            ></div>
          </div>
          <div class="flex flex-col col-span-2 lg:col-span-1">
            <label class="pb-2.5 font-bold font-raleway text-regular"
            >{{ $t("Firstname") }} <span class="text-red">*</span></label
            >
            <Field
                class="p-4 border rounded border-grisClair font-regular font-raleway"
                type="text"
                name="first_name"
                :label="$t('Firstname')"
                v-model="formData.first_name"
            />
            <span v-if="errors.first_name" class="pt-1 font-raleway text-small text-red">{{ errors.first_name }}</span>
            <div v-if="validationErrors.first_name" class="pt-1 font-raleway text-small text-red"
                 v-html="validationErrors.first_name?.join('<br />')"
            ></div>
          </div>
          <div class="flex flex-col col-span-2">
            <label class="pb-2.5 font-bold font-raleway text-regular"
            >{{ $t("Email") }} <span class="text-red">*</span></label
            >
            <Field
                class="p-4 border rounded border-grisClair font-regular font-raleway"
                type="email"
                :label="$t('Email')"
                name="email"
                v-model="formData.email"
            />
            <span v-if="errors.email" class="pt-1 font-raleway text-small text-red">{{ errors.email }}</span>
            <div v-if="validationErrors.email" class="pt-1 font-raleway text-small text-red"
                 v-html="validationErrors.email?.join('<br />')"
            ></div>
          </div>
          <div class="flex flex-col col-span-2 lg:col-span-1">
            <label class="pb-2.5 font-bold font-raleway text-regular"
            >{{ $t("person.phone") }} <span class="text-red">*</span></label
            >
            <Field
                class="p-4 border rounded border-grisClair font-regular font-raleway"
                type="text"
                name="phone"
                placeholder="+33"
                v-model="formData.phone"
            />
            <span class="pt-2.5 font-raleway text-small"
            >{{ $t("job_offer.sample-phone") }}</span
            >
            <span v-if="errors.phone" class="pt-1 font-raleway text-small text-red">{{ errors.phone }}</span>
            <div v-if="validationErrors.phone" class="pt-1 font-raleway text-small text-red"
                 v-html="validationErrors.phone?.join('<br />')">
            </div>
          </div>
          <div class="flex flex-col col-span-2">
            <label
                for="curriculum_vitae"
                class="pb-2.5 font-bold font-raleway text-regular"
            >{{ $t("job_offer.Cv") }}<span class="text-red">*</span></label
            >
            <div
                class="flex flex-col gap-2.5 lg:flex-row lg:items-center mb-2 lg:mb-0"
            >
              <label
                  for="curriculum_vitae"
                  class="flex items-center gap-1 px-6 py-4 font-bold border w-fit font-raleway text-regular border-grisClair"
              >
                {{ $t("Add a file") }}
                <NuxtIcon name="download" filled class="ml-1"/>
              </label>
              <span id="cv-file-name" class="file-box font-raleway text-regularMobile lg:text-regular"
              >{{ uploadData.curriculum_vitae ? uploadData.curriculum_vitae.name : $t("No choosen File") }}</span
              >
            </div>
            <Field
                @change="handleFileUpload($event)"
                class="hidden p-4 border border-grisClair rounded font-regular font-raleway"
                type="file"
                id="curriculum_vitae"
                name="curriculum_vitae"
            />
            <span class="pt-1 font-raleway text-small italic"
            >{{ $t("job_offer.pdf_warning") }}</span
            >
            <span v-if="errors.curriculum_vitae"
                  class="pt-1 font-raleway text-small text-red">{{ errors.curriculum_vitae }}</span>
            <div v-if="validationErrors.curriculum_vitae" class="pt-1 font-raleway text-small text-red"
                 v-html="validationErrors.curriculum_vitae?.join('<br />')">
            </div>
          </div>
          <div class="flex flex-col col-span-2">
            <label
                for="cover_letter"
                class="pb-2.5 font-bold font-raleway text-regular"
            >{{ $t("job_offer.cover_letter") }}</label
            >
            <div
                class="flex flex-col gap-2.5 lg:flex-row lg:items-center mb-2 lg:mb-0"
            >
              <label
                  for="cover_letter"
                  class="flex items-center gap-1 px-6 py-4 font-bold border w-fit font-raleway text-regular border-grisClair"
              >
                {{ $t("Add a file") }}
                <NuxtIcon name="download" filled class="ml-1"/>
              </label>
              <span id="file-name" class="file-box font-raleway text-regularMobile lg:text-regular"
              >{{ uploadData.cover_letter ? uploadData.cover_letter.name : $t("No choosen File") }}</span
              >
            </div>
            <Field
                @change="handleFileUpload($event)"
                class="hidden p-4 border rounded border-grisClair font-regular font-raleway"
                type="file"
                name="cover_letter"
                id="cover_letter"
            />
            <span class="pt-1 font-raleway text-small italic"
            >{{ $t("job_offer.pdf_warning") }}</span
            >
            <span v-if="errors.cover_letter" class="pt-1 font-raleway text-small text-red">{{
                errors.cover_letter
              }}</span>
            <div v-if="validationErrors.cover_letter" class="pt-1 font-raleway text-small text-red"
                 v-html="validationErrors.cover_letter?.join('<br />')">
            </div>
          </div>
          <div class="flex flex-col col-span-2">
            <label class="pb-2.5 font-bold font-raleway text-regular"
            >{{ $t("Message") }} <span class="text-red">*</span></label
            >
            <Field
                as="textarea"
                rows="10"
                class="p-4 border border-grisClair font-regular font-raleway"
                type="text"
                name="message"
                :label="$t('Message')"
                v-model="formData.message"
            />
            <span v-if="errors.message" class="pt-1 font-raleway text-small text-red"
            >{{ errors.message }}</span>
            <div v-if="validationErrors.message" class="pt-1 font-raleway text-small text-red"
                 v-html="validationErrors.message?.join('<br />')">
            </div>
          </div>
        </div>
        <Field type="hidden"
               name="reference"
               :label="$t('Object')"
               v-model="formData.reference"
        />
        <Field type="hidden"
               name="job"
               v-model="formData.job"
        />
        <div v-if="validationErrors.captcha_response" class="pt-1 font-raleway text-small text-red"
            >{{ validationErrors.captcha_response?.join('<br />') }}</div>
        <input type="submit"
               :value="$t('Send')"
               class="w-full hover:bg-orangeHover cursor-pointer text-center lg:w-fit mt-6 lg:mt-5 bg-orangeCurie rounded-[4px] text-white font-raleway font-bold text-regular py-4 px-6"/>

      </Form>
    </div>
    <!-- Formulaire end -->
    <!-- TODO: display this after form submission -->
    <div v-if="showSuccess">
      <div class="font-lora text-h3Medium">{{ $t("job_offer.success_title") }}</div>
      <div class="pt-4 font-raleway text-regular lg:pt-8">
        <p class="pb-4 lg:pb-6">{{ $t("job_offer.success_intro") }} </p>
        <p class="pb-4 lg:pb-6">{{ $t("job_offer.success_body") }} </p>
        <p> {{ $t("job_offer.success_end") }}</p>
      </div>
      <UiCustomLink
          v-if="JobOffer"
          @click="reset"
          :path="backLink"
          customClass="font-raleway pt-4 lg:pt-8 text-regular flex leading-[25px] underline font-bold items-center gap-2.5 pb-4 cursor-pointer hover:text-orangeCurie"
      >
        {{ $t("job.returnToAllJob") }}
      </UiCustomLink>
      <UiCustomLink
          v-else
          :path="backLink"
          customClass="font-raleway pt-4 lg:pt-8 text-regular flex leading-[25px] underline font-bold items-center gap-2.5 pb-4 cursor-pointer hover:text-orangeCurie"
      >
        {{  $t("spontaneous_job_offer.returnToJob") }}
      </UiCustomLink>
    </div>
    <!-- END TODO -->
  </div>
</template>
<script setup lang="ts">
import submitForm from "~/service/graphql/client/form/submit";
import upload from "~/service/graphql/client/form/upload";
import {Form, Field, defineRule, configure} from 'vee-validate';
import {required, email} from '@vee-validate/rules';
import {localize} from '@vee-validate/i18n';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useBackLink } from "~/composables/backlink";
const { getBackLink } = useBackLink();
const backLink = getBackLink("job_offer_list");
const gtm = useGtm();
const recaptchaInstance = useReCaptcha();
const recaptcha = async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded();
    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha("recaptcha_v3");
    return token;
};


const props = defineProps({
  JobOffer: Object,
  Spontaneous: Boolean
});

interface UploadFormData {
  cover_letter: File,
  curriculum_vitae: File
}

const uploadData = ref(<UploadFormData>{
      cover_letter: null,
      curriculum_vitae: null
    }
);

interface JobOfferFormData {
  reference: string,
  last_name: string,
  first_name: string,
  email: string,
  phone: string,
  message: string,
  curriculum_vitae: string,
  cover_letter: string,
  captcha_token: string;
  action: string;
}

const formData = ref(<JobOfferFormData>{
  reference: props.JobOffer ? props.JobOffer.entityById.fieldReference : "",
  job: props.JobOffer ? props.JobOffer.entityById.fieldTitle : "",
  last_name: null,
  first_name: null,
  email: null,
  phone: null,
  message: null,
  curriculum_vitae: "",
  cover_letter: "",
  captcha_token: null,
  action: null,
});
const emit = defineEmits(['changeFormStatus'])
defineRule('required', required);
defineRule('email', email);

configure({
  // Generates an English message locale generator
  generateMessage: localize('en', {
    messages: {
      required: 'This field is required',
      email: 'This email is not a valid email address',
    },
  }),
});

configure({
  // Generates an Frensh message locale generator
  generateMessage: localize('fr', {
    messages: {
      required: 'Ce champs est requis',
      email: 'Cette adresse e-mail n\'est pas reconnue',
    },
  }),
});

const FORM_ID = "job";

const nuxtApp = useNuxtApp();
const validationErrors = ref({});

const schema = {
  last_name: 'required',
  first_name: 'required',
  email: 'required|email',
  phone: 'required',
  message: 'required',
  curriculum_vitae: 'required',
};

const showSuccess = ref(false);

const handleFileUpload = async () => {
  uploadData.value[event.target.name] = event.target.files[0];
}


const onSubmitForm = async (values) => {
  if (uploadData.value) {
    //reset validation errors
    validationErrors.value = {};

    const {data: uploadResult, uploadError} = await upload(
        nuxtApp.$apollo.clients.default,
        FORM_ID,
        uploadData.value
    );

    if (uploadResult.webformUploadFileUpload.validationErrors.length > 0) {
      for (const index in uploadResult.webformUploadFileUpload.validationErrors) {
        let validationError = uploadResult.webformUploadFileUpload.validationErrors[index];
        validationErrors.value[validationError.element] = validationError.messages;
      }
      return;
    }

    const result = {};
    for (const [key, value] of Object.entries(uploadData.value)) {
      result[key] = false;
    }

    for (const key in uploadResult.webformUploadFileUpload.results) {
      formData.value[uploadResult.webformUploadFileUpload.results[key].element] = uploadResult.webformUploadFileUpload.results[key].fid;
    }
  }
  formData.value.captcha_token = await recaptcha();
  formData.value.action = "recaptcha_v3";
  const {data: formResult, error} = await submitForm(
      nuxtApp.$apollo.clients.default,
      FORM_ID,
      formData.value
  );

  if (formResult.submitWebform.validationErrors.length > 0) {
    for (const index in formResult.submitWebform.validationErrors) {
      let validationError = formResult.submitWebform.validationErrors[index];
      validationErrors.value[validationError.element] = validationError.messages;
    }
    return;
  }
  if (formResult.submitWebform.submission?.hasOwnProperty("id")) {
    let anchor = document.querySelector("#success");
    anchor?.scrollIntoView();
    showSuccess.value = true;

    triggerEvent();
  }
}

const triggerEvent = () => {
  //SPONTANEOUS JOB FORM
  if(!props.JobOffer) {
    gtm.trackEvent({
      event: 'spontaneous_application'
    });
  }
  //JOB FORM
  else {
    gtm.trackEvent({
      event: 'job_application',
      job_title: props.JobOffer.entityById.fieldTitle,
      job_city: props.JobOffer.entityById.fieldJobLocation.name,
      job_contract: props.JobOffer.entityById.fieldJobContract.name
    });
  }
}

const reset = (event) => {
  event.preventDefault();
  formData.value =
      {
        reference: props.JobOffer ? props.JobOffer.entityById.fieldReference : "",
        last_name: null,
        first_name: null,
        email: null,
        message: null,
        document: ""
      }
  showSuccess.value = false;

  uploadData.value = {
    cover_letter: null,
    curriculum_vitae: null
  }
  emit('changeFormStatus', true)
}
</script>