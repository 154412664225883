import { SubmitFormMutation } from "../../query/form/Submit";
import type { SubmitFormResult } from "~/interfaces/submitFormResult";
import type { SubmitFormData } from "~/interfaces/SubmitFormData";

export default function submitForm(client: any, id: string, data: object) {
  const elements = prepareFormData(data);
  const nuxtApp = useNuxtApp();

  const variables = {id: id, elements:elements,sourceEntityId: "", sourceEntityType: ""};
  return <SubmitFormResult>client.mutate({
    mutation: SubmitFormMutation,
    variables: variables,
  });
   
}

const prepareFormData = (data: object) => {
    const elements = [];
    for (const [key, value] of Object.entries(data)) {
        elements.push({
            element: key,
            value: value
        });
    }
    return elements;
}

